a {
  color: #2b7ff2;
  text-decoration: none;
}

[class*="subject-color-biology"] {
  background: #d6ead2 !important;
}

[class*="subject-color-chemistry"] {
  background: #f1d4b2 !important;
}

[class*="subject-color-physics"] {
  background: #d3edfc !important;
}

[class*="subject-color-mathematics"] {
  background: #d1d1d1 !important;
}

[class*="subject-color-maths"] {
  background: #acacad !important;
}

[class*="subject-color-english"] {
  background: #b8e3e6 !important;
}

[class*="subject-color-spoken-english"] {
  background: #78cdd2 !important;
}

[class*="subject-color-computing"], [class*="subject-color-computer-science"] {
  background: #ddeeea !important;
}

[class*="subject-color-evs"] {
  background: #b1ded2 !important;
}

[class*="subject-color-science"] {
  background: #f0edf5 !important;
}

[class*="subject-color-geometry"] {
  background: #38ada9 !important;
}

[class*="subject-color-geometry"][data-curriculum="CCSS"] {
  background: #dededf !important;
}

[class*="subject-color-design and technology"] {
  background: #e2e2ef !important;
}

[class*="subject-color-numbers-concept"], [class*="subject-color-geometry-concept"], [class*="subject-color-algebra"], [class*="subject-color-algebra-concept"], [class*="subject-color-measurement-concept"], [class*="subject-color-statistics-and-probability"], [class*="subject-color-statistics-concept"] {
  background: #dededf !important;
}

[class*="subject-color-numeracy-and-mathematics"], [class*="subject-color-numbers-and-quantities"], [class*="subject-color-functions"] {
  background: #d1d1d1 !important;
}

[class*="subject-color-earth-and-space-science"] {
  background: #c9d1db !important;
}

[class*="subject-color-earth-and-space-science h1"] {
  color: #fff !important;
}

[class*="subject-color-life-science"] {
  background: #d6ead2 !important;
}

[class*="subject-color-computing"], [class*="subject-color-network-and-the-internet"], [class*="subject-color-networks-and-the-internet"], [class*="subject-color-data-and-analysis"], [class*="subject-color-impacts-of-computing"], [class*="subject-color-algorithms and-programming"], [class*="subject-color-informatics"] {
  background: #ddeeea !important;
}

[class*="subject-color-administration-and-support"], [class*="subject-color-business-management"], [class*="subject-color-customer-service-and-sales"], [class*="subject-color-enterprise-and-entrepreneurship"], [class*="subject-color-event-management"], [class*="subject-color-finance-and-accounting"], [class*="subject-color-human-resources"], [class*="subject-color-marketing"], [class*="subject-color-project-management"] {
  background: #e5e5e5 !important;
}

[class*="subject-color-anatomy-and-physiology"], [class*="subject-color-coaching"], [class*="subject-color-health"], [class*="wellbeing-and-sport"], [class*="subject-color-nutrition-and-sport"], [class*="subject-color-outdoor-and-adventurous-activities"], [class*="subject-color-research-methods"], [class*="subject-color-sports-psychology"], [class*="subject-color-working-in-the-sports-industry"], [class*="subject-color-health"], [class*="wellbeing-and-exercise"], [class*="subject-color-fitness-testing-and-training"], [class*="subject-color-nutrition-for-sports-and-performance"] {
  background: #eff4ff !important;
}

[class*="subject-color-mens-en-natuur"] {
  background: #f8f6da !important;
}

[class*="subject-color-mens-en-maatschappij"] {
  background: #f0eae8 !important;
}

[class*="subject-color-burgerschap"] {
  background: #fff4f9 !important;
}

[class*="subject-color-kunst-en-cultuur"] {
  background: #eae2e8 !important;
}

[class*="subject-color-historische-tijd-geschiedenis"] {
  background: #ffeaec !important;
}

[class*="subject-color-wetenschap--techniek--mn"] {
  background: #eae6f6 !important;
}

[class*="subject-color-plantyn"], [class*="subject-color-environmental-sciences"] {
  background: #edebf4 !important;
}

[class*="subject-color-life-and-earth-sciences"] {
  background: #d6ead2 !important;
}

[class*="subject-color-social-studies"], [class*="subject-color-social-science"], [class*="subject-color-history"] {
  background: #d4cfe3 !important;
}

[class*="subject-color-geography"] {
  background: #eff2e6 !important;
}

[class*="subject-color-civics"], [class*="subject-color-political-science"] {
  background: #e5ece6 !important;
}

[class*="subject-color-learning-skills"] {
  background: #e7fbfa !important;
}

[class*="subject-color-life-skills"] {
  background: #e9f6fa !important;
}

[class*="subject-color-literacy-skills"] {
  background: #dff3f1 !important;
}

[class*="subject-color-kanjerkrant"], [class*="subject-color-scoodle"] {
  background: #f0edf5 !important;
}

[class*="subject-color-aardrijkskunde"] {
  background: #e5ece6 !important;
}

[class*="subject-color-electro-mechanical-engineering"] {
  background: #edebf4 !important;
}

[class*="subject-color-aarde-en-heelal"] {
  background: #c9d1db !important;
}

[class*="subject-color-cultuur-en-godsdienst"] {
  background: #f0eae8 !important;
}

[class*="subject-color-dieren"] {
  background: #d6ead2 !important;
}

[class*="subject-color-eten-en-drinken"] {
  background: #f7edf3 !important;
}

[class*="subject-color-geschiedenis"] {
  background: #ffeaec !important;
}

[class*="subject-color-kunst-en-creatief"] {
  background: #eae2e8 !important;
}

[class*="subject-color-lichaam-en-gevoel"] {
  background: #f0eae8 !important;
}

[class*="subject-color-mens-en-samenleving"] {
  background: #f5e1ed !important;
}

[class*="subject-color-natuur"] {
  background: #d6ead2 !important;
}

[class*="subject-color-school"], [class*="subject-color-sport-en-vrije-tijd"] {
  background: #d3edfc !important;
}

[class*="subject-color-wetenschap-en-techniek"] {
  background: #f1d4b2 !important;
}

[class*="subject-color-wereld"] {
  background: #e5ece6 !important;
}

[class*="subject-color-general-science"] {
  background: #f0edf5 !important;
}

[class*="subject-color-physical-science"] {
  background: #d3edfc !important;
}

.container_2dd202 {
  z-index: 1700;
  background: #f8f9fa;
  border-radius: 15px;
  flex-direction: column;
  align-items: center;
  width: 400px;
  display: flex;
  box-shadow: 0 20px 30px #343a4099;
}

.header_2dd202 {
  color: #6c757d;
  background: #f3f3f6;
  border-bottom: 1px solid #cbcbcc;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  padding: 10px 20px;
  display: flex;
}

.body_2dd202 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  padding: 20px;
  display: flex;
}

.footer_2dd202 {
  border-top: 1px solid #cbcbcc;
  align-self: stretch;
  align-items: center;
  gap: 20px;
  height: 63px;
  padding: 10px 20px;
  display: flex;
}

.shadedBackground_a9425f {
  z-index: 1600;
  background-color: #00000080;
  position: fixed;
  inset: 0;
}

.iframeContainer_a9425f {
  background: #fff;
  border-radius: 5px;
  flex-direction: column;
  display: flex;
  position: fixed;
  inset: 60px;
  overflow: hidden;
  box-shadow: 0 20px 30px #343a4099;
}

.headerStyle_a9425f {
  background: #f3f3f6;
  border-bottom: 1px solid #cbcbcc;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: 60px;
  padding: 10px 40px;
  display: flex;
}

.headerTitle_a9425f {
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;
}

.headerButtons_a9425f {
  align-items: center;
  gap: 30px;
  display: flex;
}

.nextPreviousContainer_a9425f {
  align-items: center;
  gap: 10px;
  display: flex;
}

.nextPreviousButton_a9425f {
  background-color: #bfd9fb;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.nextPreviousButton_a9425f:hover {
  background-color: #bfd9fb;
}

.nextPreviousButton_a9425f:disabled {
  opacity: .3;
  background-color: #bfd9fb;
}

.closeButton_a9425f {
  color: #6c757d;
  cursor: pointer;
  padding: 10px;
}

.iframe_a9425f {
  background-color: #fff;
  border: none;
  width: 100%;
  height: calc(100% - 60px);
}

.activeUrlLink_a9425f {
  flex-direction: column;
  place-items: center;
  height: 100%;
  padding: 7% 5%;
  font-size: 32px;
  display: flex;
  overflow-y: auto;
}

.wrapImageStyle_a9425f {
  justify-content: center;
  width: 100%;
  height: auto;
  display: flex;
}

.wrapImageStyle_a9425f img {
  object-fit: contain;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

.wrapTextStyle_a9425f {
  margin-top: 30px;
}

.textStyle_a9425f {
  text-align: center;
  color: #adb5bd;
  margin: 0;
  font-size: 16px;
  line-height: 25px;
}

.textStyle_a9425f > a {
  color: #2b7ff2;
  text-decoration: none;
}

.activeUrlLink_a9425f > a {
  color: #fff;
  background-color: #2b7ff2;
  border-radius: 5px;
  margin-top: 30px;
  padding: 10px 61px;
  font-size: 16px;
  font-weight: 700;
  line-height: 25px;
  text-decoration: none;
}

.loadingIframe_a9425f {
  background-color: #fff;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: calc(90% - 60px);
  display: flex;
  position: fixed;
}

@media (width >= 1280px) {
  .wrapImageStyle_a9425f {
    height: 40%;
  }

  .textStyle_a9425f {
    font-size: 24px;
    line-height: 35px;
  }
}

@media (width <= 991px) {
  .iframeContainer_a9425f {
    inset: 40px;
  }
}

@media (width <= 767px) {
  .iframeContainer_a9425f {
    inset: 0;
  }

  .headerStyle_a9425f {
    padding: 10px 20px;
  }

  .headerButtons_a9425f {
    width: 100%;
  }

  .closeButton_a9425f {
    padding: 0;
  }
}

@media (width <= 640px) {
  .textStyle_a9425f {
    line-height: 30px;
  }

  .loadingIframe_a9425f {
    width: 100%;
    left: 0%;
  }
}

@media screen and (height <= 640px) and (orientation: landscape) {
  .headerStyle_a9425f[data-video="true"] {
    display: none;
  }

  .iframe_a9425f[data-video="true"] {
    height: 100%;
  }

  .loadingIframe_a9425f {
    height: 90%;
  }
}

@media (width <= 400px) {
  .pages_a9425f {
    margin: 0 5px;
  }
}

.loadingContainer_eb0c9f {
  place-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.loadingContainer_eb0c9f.searchTop_eb0c9f {
  width: 100%;
  position: relative;
  top: -30px;
}

.spinner_eb0c9f {
  text-align: center;
  z-index: 900;
  width: 60px;
  height: 60px;
  margin: 100px auto;
  animation: 1s linear infinite sk-rotate_eb0c9f;
  position: relative;
}

.dot1_eb0c9f, .dot2_eb0c9f {
  background-color: #03392a;
  border-radius: 100%;
  width: 60%;
  height: 60%;
  animation: 1s ease-in-out infinite sk-bounce_eb0c9f;
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot2_eb0c9f {
  animation-delay: -.5s;
  top: auto;
  bottom: 0;
}

@-webkit-keyframes sk-rotate_eb0c9f {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes sk-rotate_eb0c9f {
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes sk-bounce_eb0c9f {
  0%, 100% {
    -webkit-transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce_eb0c9f {
  0%, 100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}

.wizeupApp_c96bb5 {
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.wizeupApp_c96bb5 img, .wizeupApp_c96bb5 p {
  margin: 0;
  padding: 0;
}

.headerContainer_ed35ef {
  box-sizing: border-box;
  background-color: #f3f3f6;
  border-bottom: 1px solid #cbcbcc;
  justify-content: space-between;
  width: 100%;
  height: 71px;
  padding: 15px 40px;
  display: flex;
}

.titleText_ed35ef {
  color: #343a40;
  align-items: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  display: flex;
}

.logoContainer_ed35ef {
  cursor: pointer;
  flex-direction: row;
  flex: none;
  order: 0;
  gap: 10px;
  height: 40px;
  display: flex;
}

.buttonsContainer_ed35ef {
  flex-grow: 1;
  flex-basis: 0%;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 10px;
  padding-left: 20px;
  display: flex;
}

@media (width <= 991px) {
  .titleText_ed35ef {
    display: none;
  }
}

@media (width <= 767px) {
  .headerContainer_ed35ef {
    padding: 15px 10px;
  }

  .buttonsContainer_ed35ef {
    gap: 10px;
    padding-left: 10px;
  }

  .subheaderWrapper_ed35ef {
    padding: 10px;
  }
}

.reviewRequests_0bb7ce {
  align-items: center;
  gap: 10px;
  display: flex;
}

.requestsNumber_0bb7ce {
  background: #fce6e7;
  border: 1px solid #e30613;
  border-radius: 15px;
  align-items: center;
  height: 22px;
  padding: 5px 8px;
  display: flex;
}

.item_58bac5 {
  background: #f3f3f6;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 5px;
  padding: 10px 20px;
  display: flex;
}

.privacyTerms_58bac5 {
  gap: 5px;
  display: flex;
}

.item_74dc27, .item_bcb158 {
  cursor: pointer;
  background: #fff;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  display: flex;
  box-shadow: inset 0 -1px #cbcbcc;
}

.icon_bcb158 {
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  display: flex;
}

.iconColorBlue_bcb158 {
  color: #2b7ff2;
  background: #bfd9fb;
  border: 1px solid #84b5f7;
}

.iconColorGreen_bcb158 {
  color: #03392a;
  background: #b3c4bf;
  border: 1px solid #819c94;
}

.item_346f97 {
  background: #fff;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  display: flex;
  position: relative;
  box-shadow: inset 0 -1px #cbcbcc;
}

.logo_346f97 {
  background: #b3c4bf;
  border: 1px solid #819c94;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  display: flex;
}

.info_346f97 {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.settings_346f97 {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 20px;
}

.menuButton_96be02 {
  cursor: pointer;
  border: 1px solid;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  width: 40px;
  min-width: 40px;
  height: 40px;
  font-size: 15px;
}

.container_2135c8 {
  z-index: 1400;
  background: none;
  position: fixed;
  inset: 0;
}

.list_2135c8 {
  z-index: 1400;
  border-radius: 0 0 10px 10px;
  max-height: 100%;
  position: fixed;
  top: 71px;
  right: 40px;
  overflow: hidden;
  box-shadow: 0 10px 10px #6c757d80;
}

@media (width <= 767px) {
  .list_2135c8 {
    right: 0;
  }
}

.positionBottomRight_2135c8 {
  inset: 71px 0 0;
}

.positionLeftFullHeightHalfWidth_2135c8 {
  inset: 0 50% 0 0;
}

.inDialogBoxStyle_2135c8 {
  top: 72px;
  bottom: 100px;
  left: 32px;
  box-shadow: 0 0 20px #343a4099;
}

@media (width <= 767px) {
  .inDialogBoxStyle_2135c8 {
    inset: 71px 0 0;
  }
}

.closeButtonContainer_2135c8 {
  background: #f3f3f6;
  border-bottom: 1px solid #cbcbcc;
  justify-content: flex-end;
  align-items: center;
  height: 40px;
  padding: 10px 20px;
  display: flex;
}

.baseContainer_096878 {
  color: #fff;
  justify-content: flex-end;
  display: flex;
}

.openContainer_096878 {
  z-index: 1400;
  background: linear-gradient(#0000 0 71px, #343a4099 71px 100%);
  padding: 0 10px;
  position: fixed;
  inset: 0;
}

.openList_096878 {
  background-color: #fff;
  border-radius: 20px;
  width: 100%;
  max-width: 971px;
  position: relative;
  top: 81px;
  overflow: hidden;
}

.desktopContainer_096878 {
  box-sizing: border-box;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #cbcbcc;
  border-radius: 20px;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.desktopContainer_096878[data-adjust-to="searching"] {
  margin-right: 0;
}

.searchForm_096878 {
  z-index: 1200;
  justify-content: space-between;
  align-items: center;
  height: 38px;
  display: flex;
  position: relative;
}

.searchForm_096878 input {
  background-color: #fff;
  border: none;
  border-radius: 20px;
  outline: none;
  flex: 100%;
  padding: 10px 20px;
}

.searchButton_096878 {
  color: #6c757d;
  cursor: pointer;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  width: 40px;
  min-width: 40px;
  height: 40px;
  margin-right: -1px;
  font-size: 15px;
  display: flex;
}

.subheaderWrapper_8011cf {
  color: #6c757d;
  opacity: .9;
  z-index: 1100;
  background-color: #fff;
  align-self: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: 40px;
  padding: 10px 40px;
  display: flex;
  position: sticky;
  top: 0;
}

@media (width <= 767px) {
  .subheaderWrapper_8011cf {
    padding: 10px;
  }
}

.backButton_df02ba {
  color: #fff;
  cursor: pointer;
  background-color: #adb5bd;
  border: none;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  width: 20px;
  min-width: 20px;
  height: 20px;
  padding: 0;
  font-size: 12px;
  display: flex;
}

.suggestionsMenu_19f97b {
  color: #323335;
  z-index: 1500;
  border-top: 1px solid #cbcbcc;
  position: relative;
  overflow-y: auto;
  transform: translateZ(0);
}

.listItem_19f97b {
  border-top: 1px solid #cbcbcc;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 10px 20px;
  display: flex;
}

.listItem_19f97b:first-child {
  border-top: unset;
}

.listItem_19f97b:hover {
  background: #2b7ff21a;
}

.listItemImage_19f97b {
  max-width: 55px;
  max-height: 55px;
}

.listItemData_19f97b {
  flex-direction: column;
  gap: 5px;
  display: flex;
}

.listItemLogoAndTitle_19f97b {
  align-items: center;
  gap: 10px;
  display: flex;
}

.title_19f97b {
  -webkit-line-clamp: 1;
  word-break: break-all;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.listItemPath_19f97b {
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  display: flex;
}

.introModalDialog_c20508 {
  pointer-events: none;
  flex-direction: column;
  justify-content: space-between;
  width: auto;
  max-width: 800px;
  height: 100%;
  display: flex;
  position: relative;
}

.nextPrevButton_c20508 {
  color: #fff;
  background-color: #2b7ff2 !important;
  border-radius: 50% !important;
}

.nextPrevButton_c20508:disabled {
  background-color: #adb5bd;
}

.introContent_c20508 {
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-radius: 15px;
  outline: 0;
  flex-direction: column;
  gap: 20px;
  width: 487px;
  height: 525px;
  margin: 0;
  padding: 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.introContent_c20508 .textCarrousel_c20508 {
  font-size: 1.2rem;
  font-weight: 500;
}

.introContent_c20508 .enable_c20508 {
  flex-direction: column;
  height: 100%;
  display: flex;
}

.introContent_c20508 .disable_c20508 {
  display: none;
}

.introHeader_c20508 {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.introTitle_c20508 {
  color: #fff;
  margin-top: .5rem;
  margin-bottom: 0;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.5;
}

.introBody_c20508 {
  text-align: center;
  color: #dee2e6;
  flex: auto;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.introBody_c20508:before {
  content: " ";
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.introBody_c20508 img {
  opacity: 1;
  vertical-align: middle;
  border-style: none;
  max-width: 100%;
  height: auto;
  width: 65% !important;
}

.introBody_c20508 img.wizeupLogo_c20508 {
  margin-top: 1rem;
  width: 45% !important;
}

.introBody_c20508.introTour2_c20508 {
  padding-top: 6rem;
}

.introBody_c20508.introTour2_c20508:before {
  opacity: .15;
}

.introTour3Grid_c20508 {
  grid-gap: 10px;
  grid-template-columns: 40% 60%;
  height: 100%;
  padding: 10px;
  display: grid;
}

.introTour3Text_c20508 {
  align-items: center;
  display: flex;
}

.introBody_c20508.introTour3_c20508 {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.introBody_c20508.introTour3_c20508:before {
  background: green 0 0 / 400px;
  width: 95%;
  top: 0;
  left: 0;
}

.introBody_c20508.introTour4_c20508 {
  height: 100%;
  padding: .8rem;
  display: flex;
}

.introBody_c20508.introTour4_c20508:before {
  background: purple;
  width: 100%;
  height: 110%;
  left: 2%;
}

.introBody_c20508.introTour4_c20508 .cards_c20508 {
  width: 70%;
}

.multiLineTextWrap_c20508 {
  width: 246px;
  line-height: 24px;
}

.sliderControlWrapper_c20508 {
  justify-content: space-between;
  padding: 20px;
  display: flex;
}

.modalProgressMarks_c20508 svg {
  color: #ced4da;
  opacity: .5;
  margin: 3px;
  font-size: .8rem;
}

svg.progressActiveMark_c20508 {
  opacity: .8;
}

.skipProgressWrapper_c20508 {
  align-items: center;
  display: flex;
}

.introFooter_c20508 {
  z-index: 10;
  border: none;
  flex-wrap: wrap;
  align-items: center;
  padding: .75rem;
  display: flex;
  justify-content: space-between !important;
}

.introFooter_c20508 > * {
  margin: .25rem;
}

.introFooter_c20508.centerItem_c20508 {
  justify-content: center !important;
}

.button_c20508 {
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: #0000;
  border: 1px solid #0000;
  border-radius: .25rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

.button_c20508.skipButton_c20508 {
  color: #2b7ff2;
  font-weight: 400;
  text-decoration: underline;
}

.button_c20508.nextButton_c20508 {
  color: #504691;
  background: #f8f9fa linear-gradient(#f8f9fb, #f8f9fa) repeat-x;
  border-color: #f8f9fa;
  width: 4rem;
  height: 4rem;
  padding: 0;
  font-weight: 700;
  border-radius: 50% !important;
}

.button_c20508.endButton_c20508 {
  color: #fff;
  background: #2b7ff2;
  border-radius: 5px;
  flex-grow: 0;
  order: 3;
  width: 320px;
  height: 45px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}

.button_c20508.endButton_c20508 svg {
  color: #adb5bd;
  padding-right: 6px;
}

img.searchLargeIcon_c20508 {
  width: 35% !important;
}

@media (width <= 575.98px) {
  .introContent_c20508 {
    width: 100%;
    height: 90vh;
    padding: 0;
  }

  .introTour1_c20508 {
    margin-bottom: 4.3rem;
  }

  .introTour2_c20508 {
    margin-bottom: 6rem;
  }

  .introTour3_c20508 {
    padding: .7rem !important;
  }

  .introTour3_c20508:before, .introTour4_c20508:before {
    background: none !important;
  }

  .introHeader_c20508 {
    text-align: center;
    border: none;
    padding: 1rem;
  }

  .introTitle_c20508 {
    text-align: center;
    margin: .5rem auto;
    font-size: 1.4rem;
  }

  .introBody_c20508 {
    padding: 0;
    padding-top: 2rem !important;
  }

  .popover_c20508 {
    display: none;
  }
}

.imageStyle_1a625f {
  border-radius: 15px;
  width: 100%;
}

.textContainer_1a625f {
  padding: 0 20px;
}

.title_f42c07 {
  color: #343a40;
  text-align: center;
  background: #f3f3f6;
  border-bottom: 1px solid #cbcbcc;
  padding: 10px 20px;
  font-size: 18px;
  line-height: 25px;
}

.listContainer_c52cc2 {
  flex-direction: column;
  gap: 5px;
  width: 100%;
  margin: 0;
  padding: 0 20px;
  display: flex;
  overflow-y: auto;
}

.suggestionsListItem_d3442a {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  box-sizing: border-box;
  color: #000;
  background-color: #fff;
  border: 1px solid #e8e7e9;
  border-radius: 15px;
  align-items: center;
  width: 100%;
  padding: 15px 20px;
  scroll-margin: 20px;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  transition: color .25s, background .25s;
  display: flex;
}

.suggestionsListItem_d3442a[data-selected="true"] {
  background-color: #f0f6ff;
  border: 1px solid #2b7ff2;
}

.suggestionsListItemText_d3442a {
  word-break: break-word;
  word-wrap: break-word;
  text-align: left;
}

.radioStyle_d3442a {
  border-color: #e8e7e9;
  width: 20px;
  height: 20px;
  margin: 0 10px 0 0;
  padding: 0;
}

.title_6e05e9 {
  word-break: break-all;
  border-bottom: 1px solid #cbcbcc;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 2px;
  display: flex;
  overflow: hidden;
}

.subheader_c1e30e {
  background: #fff;
  border-bottom: 1px solid #e8e7e9;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 10px 40px;
  display: flex;
}

@media (width <= 767px) {
  .subheader_c1e30e {
    padding: 10px;
  }
}

.subheaderGroup_c1e30e {
  align-items: center;
  gap: 20px;
  width: 100%;
  display: flex;
}

@media (width <= 767px) {
  .subheaderGroup_c1e30e {
    justify-content: space-between;
  }
}

.editAndMobileExplorer_c1e30e {
  align-items: center;
  gap: 20px;
  display: flex;
}

.editButton_c1e30e {
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 30px;
  padding: 10px 20px;
  display: flex;
}

.newButton_c1e30e {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 30px;
  padding: 10px 20px;
  display: flex;
}

.previewQuizButton_c1e30e {
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 30px;
  padding: 10px 20px;
  display: flex;
}

@media (width <= 767px) {
  .previewQuizButton_c1e30e {
    color: #2b7ff2;
    background: #bfd9fb;
    border: 1px solid #84b5f7;
    border-radius: 50%;
    width: 30px;
    padding: 10px;
  }
}

.mobileContainer_c1e30e {
  background: #fff;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  height: 100%;
  padding: 20px;
  display: flex;
}

.mobileButton_c1e30e {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 30px;
  padding: 0;
  display: flex;
}

@media (width <= 767px) {
  .mobileButton_c1e30e {
    padding: 10px 20px;
  }
}

.shareButton_c1e30e {
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 30px;
  padding: 10px 20px;
  display: flex;
}

@media (width <= 991px) {
  .shareButton_c1e30e {
    border: 1px solid #2b7ff2;
    border-radius: 50%;
    width: 30px;
    padding: 10px;
  }
}

.tutorShareButton_c1e30e {
  color: #9b1de8;
  border: 1px solid #9b1de880;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 30px;
  padding: 10px 20px;
  display: flex;
}

@media (width <= 991px) {
  .tutorShareButton_c1e30e {
    border-radius: 50%;
    width: 30px;
    padding: 10px;
  }
}

.title_2542ba {
  word-break: break-word;
  background: #f3f3f6;
  border-bottom: 1px solid #cbcbcc;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
  padding: 10px 20px;
  display: flex;
}

@media (width <= 767px) {
  .title_2542ba {
    padding: 10px;
  }
}

.closeButton_2542ba {
  color: #6c757d;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
}

.container_5559ca {
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 10px 20px;
  display: flex;
}

@media (width <= 767px) {
  .container_5559ca {
    padding: 10px;
  }
}

.button_5559ca {
  padding: 16px 30px;
  font-size: 16px;
  line-height: 1;
}

@media (width <= 767px) {
  .button_5559ca {
    padding: 10px 20px;
  }
}

.mainContainer_0103e1 {
  background-color: #fff;
  border-radius: 10px;
  flex-direction: column;
  width: 900px;
  display: flex;
  box-shadow: 0 20px 30px #343a4099;
}

.headerContainer_0103e1 {
  color: #6c757d;
  background: #f3f3f6;
  border-bottom: 1px solid #cbcbcc;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  display: flex;
}

.tabsContainer_0103e1 {
  flex-direction: column;
  padding: 30px 40px 10px;
  display: flex;
}

.shareContainer_0103e1 {
  gap: 20px;
  display: flex;
}

.textContainer_0103e1 {
  background: #f8f9fa;
  border: 1px solid #e8e7e9;
  border-radius: 5px;
  max-height: 120px;
  padding: 16px 20px 24px;
  overflow-y: scroll;
}

.text_0103e1 {
  color: #6c757d;
  word-wrap: break-word;
  width: 100%;
  font-family: monospace;
  font-size: 14px;
  line-height: 24px;
}

.iframeContainer_0103e1 {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.iframeTitle_0103e1 {
  color: #343a40;
  padding-bottom: 5px;
  font-size: 16px;
  font-weight: 700;
  line-height: 25px;
}

.sizeContainer_0103e1 {
  justify-content: space-between;
  align-items: center;
  width: 115px;
  display: flex;
}

.styleLabel_0103e1 {
  color: #343a40;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
}

.sizeInput_0103e1 {
  text-align: center;
  color: #343a40;
  border: 1px solid #cbcbcc;
  border-radius: 4px;
  width: 50px;
  padding: 5px;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
}

.sizeInput_0103e1::-webkit-inner-spin-button {
  appearance: none;
}

.previewButton_0103e1 {
  align-self: flex-start;
  padding: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-decoration: underline;
}

.copyContainer_0103e1 {
  border-top: 1px solid #cbcbcc;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  display: flex;
}

.copyText_0103e1 {
  padding-left: 10px;
  font-size: 14px;
  font-weight: 700;
}

.copyCorrect_0103e1 {
  color: #5fc3ad;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
}

.copyButton_0103e1 {
  padding: 10px 20px;
  font-size: 16px;
}

@media (width <= 991px) {
  .mainContainer_0103e1 {
    width: 500px;
  }

  .headerContainer_0103e1 {
    padding: 10px 20px;
  }

  .tabsContainer_0103e1 {
    padding: 30px 20px 10px;
  }

  .textContainer_0103e1 {
    max-height: 210px;
  }

  .copyContainer_0103e1 {
    padding: 20px;
  }
}

@media (width <= 767px) {
  .mainContainer_0103e1 {
    width: 320px;
  }

  .headerContainer_0103e1 {
    padding: 7.5px 15px;
  }

  .headerTitle_0103e1 {
    font-size: 18px;
    line-height: 25px;
  }

  .headerCloseButton_0103e1 {
    padding: 0;
  }

  .tabsContainer_0103e1 {
    padding: 30px 15px 10px;
  }

  .shareContainer_0103e1 {
    flex-direction: column;
  }

  .textContainer_0103e1 {
    max-height: 230px;
  }

  .iframeContainer_0103e1 {
    padding-bottom: 10px;
  }

  .copyContainer_0103e1 {
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
    padding: 15px;
  }

  .copyCorrect_0103e1 {
    margin-bottom: 10px;
  }

  .copyButton_0103e1 {
    width: 100%;
  }

  .label_1cf64a {
    display: none;
  }
}

.tooltipContainer_2a4b50 {
  justify-content: center;
  display: flex;
  position: relative;
}

.tooltipContainer_2a4b50:hover .tooltip_2a4b50 {
  display: block;
}

.tooltip_2a4b50 {
  text-align: center;
  color: #6c757d;
  z-index: 11;
  background-color: #f8f9fa;
  border: 1px solid #cbcbcc;
  border-radius: 3px;
  width: max-content;
  height: 20px;
  padding: 0 5px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  display: none;
  position: absolute;
  bottom: 32px;
}

.subheader_ad0898 {
  background: #fff;
  border-bottom: 1px solid #e8e7e9;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 10px 40px;
  display: flex;
}

.subheaderGroup_ad0898 {
  align-items: center;
  gap: 20px;
  width: 100%;
  display: flex;
}

@media (width <= 767px) {
  .subheader_ad0898 {
    padding: 10px;
  }

  .subheaderGroup_ad0898 {
    justify-content: space-between;
  }
}

.container_953635 {
  background: #fff;
  border: 1px solid #cbcbcc;
  border-radius: 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding: 20px;
  display: flex;
}

@media (width <= 991px) {
  .container_953635 {
    border-radius: 0;
  }
}

.sectionTitle_953635 {
  border-bottom: 1px solid #e8e7e9;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  padding-bottom: 5px;
  display: flex;
}

.iconTitleTooltip_953635 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
}

.Ey20UG_treeleaf, .Ey20UG_treebranch {
  list-style-type: disc;
}

.Ey20UG_treetitle {
  word-break: break-all;
}

.generateQuizButton_f00866 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 30px;
  padding: 10px 20px;
  display: flex;
}

@media (width <= 767px) {
  .generateQuizButton_f00866 {
    border-radius: 50%;
    width: 30px;
    padding: 10px;
  }
}

.inputLabel_0c7b26 {
  color: #6c757d;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.card_3e161a {
  background-color: #fff;
  border: 1px solid #e8e7e9;
  border-radius: 15px;
  max-width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 5px 20px #6c757d33;
}

.container_3e161a {
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 475px;
  padding: 10px;
  display: flex;
  position: relative;
}

.resultGridContainer_3e161a {
  padding: 20px 40px;
}

.visualContainer_3e161a {
  justify-content: center;
  height: 150px;
  display: flex;
  position: relative;
}

.imageContainer_3e161a {
  border-radius: 5px;
  justify-content: center;
  place-items: center;
  min-height: 150px;
  max-height: 150px;
  display: flex;
  overflow: hidden;
}

div[data-numberofresults="5"] .container_3e161a:first-child[data-large-screen="true"][data-mobile-screen="false"] .imageContainer_3e161a {
  grid-row: 1 / -1;
  grid-column-start: 2;
  justify-self: end;
  min-width: 275px;
  max-width: 420px;
  max-height: 500px;
}

.container_3e161a:first-child[data-large-screen="true"] .imageCrop_3e161a {
  max-height: 500px;
}

.img_3e161a {
  object-fit: cover;
  cursor: pointer;
  border-radius: 5px;
  max-width: 100%;
  margin: 0;
  padding: 20px;
}

.videoIconContainer_3e161a {
  cursor: pointer;
  position: absolute;
  bottom: 40px;
  right: 55px;
}

.videoIcon_3e161a {
  color: #e30613;
  background-color: #fff;
  border: 2px solid #03392a;
  border-radius: 50%;
  font-size: 3.8rem;
  position: absolute;
  left: 16px;
}

.videoIconContainer_3e161a svg.videoIcon_3e161a {
  width: 30px;
  height: 30px;
}

.textContainer_3e161a {
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  display: flex;
  overflow: hidden;
}

@media (width <= 767px) {
  .resultGridContainer_3e161a {
    padding: 15px 20px;
  }
}

.footer_3e161a {
  border-top: 1px solid #e8e7e9;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding-top: 10px;
  display: flex;
}

.container_be1503 {
  white-space: nowrap;
  border-top: 1px solid #e8e7e9;
  align-self: stretch;
  align-items: center;
  gap: 5px;
  min-height: 24px;
  padding-top: 3px;
  display: flex;
  overflow: hidden;
}

.iconAndName_be1503 {
  align-items: center;
  gap: 5px;
  display: flex;
  overflow: hidden;
}

.name_be1503 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.container_968df0 {
  background: #f8f9fa;
  border-top: 1px solid #e8e7e9;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  margin: 0 -20px -10px;
  padding: 10px 15px;
  display: flex;
}

.buttonStyle_968df0 {
  align-items: center;
  gap: 5px;
  height: 24px;
  padding: 5px 10px;
  display: flex;
}

.relativeWrapper_e9072e {
  position: relative;
}

.container_e9072e {
  background-color: #fff;
  border: 1px solid #e8e7e9;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 5px 20px #6c757d33;
}

.dragHandle_e9072e {
  color: #adb5bd;
  cursor: move;
  flex: 0 0 20px;
  justify-content: center;
  align-items: center;
  gap: 1px;
  display: flex;
}

.card_e9072e {
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  width: 100%;
  padding: 10px;
  display: flex;
}

.header_e9072e {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 20px;
  display: flex;
}

.source_e9072e {
  cursor: pointer;
  align-items: center;
  gap: 10px;
  display: flex;
}

.sourceLogo_e9072e {
  flex: 0 0 20px;
  max-height: 20px;
}

.sourceUrl_e9072e {
  text-wrap: nowrap;
  color: #adb5bd;
  text-decoration: none;
}

.userProvidedLogo_e9072e {
  color: #03392a;
  background: #eaeaea;
  border: 1px solid #03392a;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  display: flex;
}

.body_e9072e {
  cursor: pointer;
  gap: 10px;
  width: 100%;
  max-height: 122px;
  text-decoration: none;
  display: flex;
}

.bodyImageContainer_e9072e {
  border: 1px solid #e8e7e9;
  border-radius: 6px;
  flex: 0 0 120px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.bodyImage_e9072e {
  width: 120px;
  height: 120px;
}

.bodyVideoIcon_e9072e {
  color: #e30613;
  background-color: #fff;
  border-radius: 15px;
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.bodyTexts_e9072e {
  flex-direction: column;
  gap: 10px;
  display: flex;
  overflow: hidden;
}

.footer_e9072e {
  border-top: 1px solid #e8e7e9;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding-top: 10px;
  display: flex;
}

.footerChip_e9072e {
  align-items: center;
  gap: 5px;
  display: flex;
}

.readingLevel_e9072e {
  background: #fff;
  border: 1px solid #819c94;
  border-radius: 30px;
  align-items: center;
  gap: 5px;
  height: 20px;
  padding: 5px 3px 5px 8px;
  display: flex;
}

.readingLevelBubble_e9072e {
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  display: flex;
}

.divider_e9072e {
  color: #819c94;
  font-size: .25rem;
}

.querySelectFeedback_e9072e {
  background: #f3f3f6;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
  display: flex;
  position: relative;
}

.select_e9072e {
  letter-spacing: 0;
  text-align: left;
  background-color: #fff;
  border: 1px solid #cbcbcc;
  border-radius: 5px;
  align-items: center;
  gap: 5px;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  display: flex;
}

.checkbox_e9072e {
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin: 0;
}

.deleteButton_e9072e {
  color: #adb5bd;
  cursor: pointer;
}

.deleteButton_e9072e :hover {
  color: #e30613;
}

.queryCardBodyContent_f9bcba {
  flex-direction: column;
  height: 280px;
  overflow: hidden;
}

.cardTitle_f9bcba {
  color: #2b7ff2;
  margin-top: 16px;
  margin-bottom: .75rem;
  font-weight: 500;
}

.cardTitleLink_f9bcba {
  cursor: pointer;
  max-width: 100%;
  margin: 10px 0;
  line-height: 35px;
  text-decoration: none;
  display: inline-block;
  overflow: hidden;
}

.cardText_f9bcba {
  color: #6c757d;
  min-height: 78px;
  font-size: 16px;
  line-height: 26px;
  display: inline-block;
  overflow: hidden;
}

.cardText_f9bcba > p {
  margin: 0;
  padding: 0;
}

.cardText_f9bcba > p[data-limit="true"] {
  -webkit-line-clamp: 6;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.missingQueryTerms_f9bcba {
  color: #6c757d;
  font-size: 80%;
  font-weight: 400;
}

.missingQueryTerms_f9bcba span {
  margin-right: .3em;
  text-decoration: line-through;
}

.sourceDecriptorWrapper_f9bcba {
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
  margin-top: 1.95rem;
  margin-bottom: .25rem;
  display: flex;
}

.cardTitle_d4b81e {
  color: #2b7ff2;
  text-align: left;
  margin: 10px 0;
}

.cardTitleLink_d4b81e {
  text-align: left;
  cursor: pointer;
  max-width: 100%;
  text-decoration: none;
  display: inline-block;
  overflow: hidden;
}

.listContainer_bb207f {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.container_bab43b {
  background: #f3f3f6;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  margin: -5px -20px -10px;
  padding: 10px 20px;
  display: flex;
}

.select_bab43b {
  letter-spacing: 0;
  text-align: left;
  background-color: #fff;
  border: 1px solid #cbcbcc;
  border-radius: 5px;
  align-items: center;
  gap: 5px;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  display: flex;
}

.checkbox_bab43b {
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin: 0;
}

.feedbackButton_497c7a {
  cursor: pointer;
  background-color: #ff7119;
  border: none;
  border-radius: 5px;
  padding: 0 5px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  display: flex;
  top: 10px;
  left: 10px;
}

.feedbackButton_497c7a > label {
  color: #fff;
  cursor: pointer;
  margin: 5px;
  font-size: 12px;
}

.feedbackButton_497c7a[data-feedback-provided="true"] {
  background-color: #19b14d;
}

.feedbackForm_df97fe {
  z-index: 100;
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  max-width: 400px;
  height: 275px;
  position: absolute;
  bottom: 0;
  right: 0;
  box-shadow: 0 5px 20px #6c757d80;
}

.feedbackMainFrom_df97fe {
  height: 215px;
  padding: 0 20px;
  overflow: auto;
}

.feedbackMainFrom_df97fe > div {
  margin: 30px 0;
}

.mandatory_48e9a7 {
  color: #e30613;
}

.reasonList_48e9a7 {
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
  margin: 15px 0;
  padding: 0;
  display: flex;
}

.formFooter_98303f {
  border-top: 1px solid #cbcbcc;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 60px;
  display: flex;
  position: absolute;
  bottom: 0;
  overflow: hidden;
}

.formFooter_98303f > button {
  margin: 10px;
}

.submitButton_98303f {
  color: #fff;
  cursor: pointer;
  background-color: #2b7ff2;
  border: none;
  border-radius: 5px;
  height: 40px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 400;
}

.submitButton_98303f[disabled] {
  background-color: #cbcbcc;
}

.cancelButton_98303f {
  color: #6c757d;
  cursor: pointer;
  background: #fff;
  border: none;
  font-size: 14px;
  font-weight: 400;
}

.checkbox_7bb2a9 {
  width: 20px;
  height: 20px;
}

.reasonList_7bb2a9 {
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
  margin: 15px 0;
  padding: 0;
  display: flex;
}

.mandatory_7bb2a9 {
  color: #e30613;
}

.listItemCheckbox_7bb2a9 {
  margin: 0;
  padding: 0;
  list-style: none;
}

.labelCheckbox_7bb2a9 {
  align-items: center;
  gap: 10px;
  display: flex;
}

.selectReason_a00eed {
  box-sizing: border-box;
  cursor: pointer;
  background: #fff;
  border: 1px solid #cbcbcc;
  border-radius: 5px;
  width: 100%;
  height: 40px;
  padding: 10px;
  font-size: 16px;
  position: relative;
  top: 10px;
}

.mandatory_a00eed {
  color: #e30613;
}

.type_860aed {
  border-bottom: 1px solid #e8e7e9;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 2px;
  display: flex;
}

.container_b52257 {
  background: #fff;
  border: 1px solid #e8e7e9;
  border-radius: 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  padding: 15px 10px 10px;
  display: flex;
  box-shadow: 0 5px 20px #6c757d33;
}

.header_b52257 {
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  height: 0;
  display: flex;
}

.notification_b52257 {
  justify-content: center;
  align-items: center;
  gap: 5px;
  display: flex;
}

.deleteButton_b52257 {
  color: #adb5bd;
}

.deleteButton_b52257:hover {
  color: #e30613;
  cursor: pointer;
}

.iconAndTitle_b52257 {
  align-self: stretch;
  align-items: center;
  gap: 5px;
  display: flex;
}

.title_b52257 {
  -webkit-line-clamp: 1;
  word-break: break-all;
  cursor: pointer;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.footer_b52257 {
  flex-wrap: wrap;
  align-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding-top: 10px;
  display: flex;
}

.divider_b52257 {
  color: #819c94;
  font-size: .25rem;
}

.container_c96958 {
  background: linear-gradient(#fff 45.77%, #f3f3f6 89.77%);
  border: 1px solid #e8e7e9;
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
  display: flex;
  position: relative;
  box-shadow: 0 5px 20px #6c757d33;
}

.header_c96958 {
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  height: 10px;
  display: flex;
}

.image_c96958 {
  height: 32px;
}

.title_c96958 {
  -webkit-line-clamp: 1;
  word-break: break-all;
  cursor: pointer;
  text-align: center;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.title_b279f7 {
  -webkit-line-clamp: 1;
  word-break: break-all;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

@media (width <= 767px) {
  .title_b279f7 {
    text-wrap: nowrap;
  }
}

.questions_e57ff3 {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.container_0089e3 {
  background: #fff;
  border-radius: 15px;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 5px;
  padding: 10px;
  display: flex;
  box-shadow: 0 1px 2px #6c757d3d, 0 4px 8px #00000014;
}

.header_0089e3 {
  align-self: stretch;
  align-items: flex-start;
  gap: 5px;
  min-height: 25px;
  display: flex;
}

.answers_0089e3 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  padding: 10px 0 10px 20px;
  display: flex;
}

.answer_0089e3 {
  align-items: center;
  gap: 10px;
  display: flex;
}

.circle_0089e3 {
  border: 1px solid #cbcbcc;
  border-radius: 50%;
  width: 17px;
  min-width: 17px;
  height: 17px;
}

.section_b82b9b {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 5px;
  display: flex;
}

.sources_b82b9b {
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
}

.container_d46b0d {
  background: #f3f3f6;
  border: 1px solid #e8e7e9;
  border-radius: 10px;
  align-items: flex-start;
  gap: 10px;
  padding: 10px;
  display: flex;
}

.checkbox_d46b0d {
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin: 0;
}

.body_d46b0d {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
  display: flex;
}

.logoAndTitle_d46b0d {
  align-items: flex-start;
  gap: 5px;
  display: flex;
}

.panel_ae79aa {
  background: #fff;
  border: 1px solid #cbcbcc;
  border-radius: 15px;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px 0 20px;
  display: flex;
}

@media (width <= 991px) {
  .panel_ae79aa {
    border-radius: 0;
    flex: 1;
    overflow-y: auto;
  }
}

.header_ae79aa {
  border-bottom: 2px solid #cbcbcc;
  align-self: stretch;
  height: 31px;
  padding-left: 10px;
  display: flex;
}

.title_ae79aa {
  border-bottom: 2px solid #6c757d;
  align-items: center;
  height: 31px;
  padding: 10px;
  display: flex;
}

.comments_ae79aa {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  max-height: 700px;
  padding: 10px 10px 0;
  display: flex;
  overflow-y: auto;
}

@media (width <= 991px) {
  .comments_ae79aa {
    max-height: unset;
    overflow-y: unset;
  }
}

.addComment_ae79aa {
  gap: 10px;
  height: 30px;
  padding: 10px 0;
}

.newComment_ae79aa {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  padding: 10px 0;
  display: flex;
}

.noComments_ae79aa {
  border-bottom: 1px solid #e8e7e9;
  align-self: stretch;
  align-items: flex-start;
  margin: 10px 0;
  display: flex;
}

.comment_ae79aa {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 5px;
  padding: 10px 0;
  display: flex;
}

.commentHeader_ae79aa {
  white-space: nowrap;
  border-bottom: 1px solid #e8e7e9;
  align-self: stretch;
  align-items: center;
  gap: 5px;
  display: flex;
  overflow: hidden;
}

.iconAndName_ae79aa {
  align-items: center;
  gap: 5px;
  display: flex;
  overflow: hidden;
}

.name_ae79aa {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.newCommentFooter_ae79aa {
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  display: flex;
}

.footerButton_ae79aa {
  height: 24px;
  padding: 10px;
  font-size: 12px;
}

.info_18ec48 {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.appearance_18ec48 {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.container_2a7250 {
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  display: flex;
}

.tooltipOn_2a7250 {
  cursor: help;
  text-decoration: underline dotted;
}

.container_832ee9 {
  flex-direction: column;
  gap: 5px;
  max-width: 128px;
  display: flex;
}

.text_832ee9 {
  color: #6c757d;
  font-size: 10px;
  line-height: 12px;
}

.url_832ee9 {
  color: #2b7ff2;
  cursor: pointer;
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  text-decoration: underline;
}

.container_e51a2b {
  word-break: break-all;
  border-bottom: 1px solid #e8e7e9;
  margin: 10px 40px 0;
  display: inline-block;
}

@media (width <= 767px) {
  .container_e51a2b {
    margin: 10px;
  }
}

.titleInputField_e51a2b {
  color: #343a40;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  font-family: system-ui, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

.editIcon_e51a2b {
  color: #6c757d;
  font-family: Font Awesome 5 Free;
  cursor: pointer;
  width: 17px;
  font-size: 15px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px;
}

.outerContainer_f042f7 {
  background: #fff;
  border: 1px solid #cbcbcc;
  border-radius: 15px;
  margin-bottom: 20px;
  padding: 20px;
}

.innerContainer_f042f7 {
  background: #f3f3f6;
  border: 1px solid #e8e7e9;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 20px;
  display: flex;
}

.title_f042f7 {
  border-bottom: 1px solid #e8e7e9;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  padding: 5px 0;
  display: flex;
}

.buttonContainer_f042f7 {
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  display: flex;
}

@media (width <= 767px) {
  .buttonContainer_f042f7 {
    flex-direction: column;
    gap: 10px;
  }
}

.container_84d391 {
  background-color: #fff;
  border: 1px solid #cbcbcc;
  border-radius: 15px;
  flex-direction: column;
  padding: 20px;
  display: flex;
}

.actionsContainer_84d391 {
  border-top: 1px solid #cbcbcc;
  flex-direction: row-reverse;
  align-items: center;
  margin: 20px -20px -10px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
}

.KMkT7a_urlFieldContainer {
  align-items: center;
  gap: 10px;
  display: flex;
}

.settings_2e383c {
  letter-spacing: 0;
  text-align: left;
  color: #343a40;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
}

.container_2fe8f8 {
  align-items: center;
  gap: 10px;
  display: flex;
}

.container_b69d2b {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

@media (width <= 991px) {
  .container_b69d2b {
    gap: 10px;
  }
}

.mobileFilterAndMessage_b69d2b {
  align-items: center;
  gap: 10px;
  display: flex;
}

.resultsList_b69d2b {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.container_5d0419 {
  background: #fff;
  border: 1px solid #e8e7e9;
  border-radius: 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  padding: 10px;
  display: flex;
  box-shadow: 0 5px 20px #6c757d33;
}

.header_5d0419 {
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.body_5d0419 {
  align-items: center;
  gap: 10px;
  display: flex;
}

.bodyImage_5d0419 {
  border: 1px solid #e8e7e9;
  border-radius: 6px;
  width: 118px;
  height: 118px;
}

.bodyTexts_5d0419 {
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  gap: 15px;
  display: flex;
}

.bodyTitle_5d0419 {
  align-self: stretch;
  align-items: center;
  gap: 10px;
  display: flex;
}

.title_5d0419 {
  line-clamp: 1;
  -webkit-line-clamp: 1;
  word-break: break-all;
  cursor: pointer;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.footer_5d0419 {
  flex-wrap: wrap;
  align-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding-top: 10px;
  display: flex;
}

.divider_5d0419 {
  color: #819c94;
  font-size: .25rem;
}

.container_1861dc {
  background: #fff;
  border-bottom: 1px solid #e8e7e9;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
  display: flex;
}

@media (width <= 767px) {
  .container_1861dc {
    padding: 10px;
  }
}

.editAndMobileExplorer_1861dc {
  align-items: center;
  gap: 20px;
  display: flex;
}

.editButton_1861dc {
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 30px;
  padding: 10px 20px;
  display: flex;
}

@media (width <= 991px) {
  .editButton_1861dc {
    border: 1px solid #2b7ff2;
    border-radius: 50%;
    width: 30px;
    padding: 10px;
  }
}

@media (width <= 767px) {
  .editButton_1861dc {
    border: 1px solid #2b7ff2;
    border-radius: 5px;
    width: 100%;
    padding: 10px 20px;
  }
}

.mobileContainer_1861dc {
  background: #fff;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
}

.deleteButton_878187 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 30px;
  padding: 10px 20px;
  display: flex;
}

@media (width <= 991px) {
  .deleteButton_878187 {
    border-radius: 50%;
    width: 30px;
    padding: 10px;
  }
}

@media (width <= 767px) {
  .deleteButton_878187 {
    border-radius: 5px;
    width: 100%;
    padding: 10px 20px;
  }
}

.saveButton_d3f496 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 30px;
  padding: 10px 20px;
  display: flex;
}

@media (width <= 991px) {
  .saveButton_d3f496 {
    border-radius: 50%;
    width: 30px;
    padding: 10px;
  }
}

@media (width <= 767px) {
  .saveButton_d3f496 {
    border-radius: 5px;
    width: 100%;
    padding: 10px 20px;
  }
}

.wrapper_19f002 {
  position: relative;
}

.overlay_19f002 {
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.shareButton_19f002 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 30px;
  padding: 10px 20px;
  display: flex;
}

@media (width <= 991px) {
  .shareButton_19f002 {
    border-radius: 50%;
    width: 30px;
    padding: 0;
  }
}

.shareButton_368605 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 30px;
  padding: 10px 20px;
  display: flex;
}

@media (width <= 991px) {
  .shareButton_368605 {
    border-radius: 50%;
    width: 30px;
    padding: 0;
  }
}

.wrapper_931c8c {
  position: relative;
}

.overlay_931c8c {
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.shareButton_931c8c {
  color: #9b1de8;
  border: 1px solid #9b1de880;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 30px;
  padding: 10px 20px;
  display: flex;
}

@media (width <= 991px) {
  .shareButton_931c8c {
    border-radius: 50%;
    width: 30px;
    padding: 10px;
  }
}

.container_2677c1 {
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 60px;
  display: flex;
}

.image_2677c1 {
  width: 270px;
}

.text_2677c1 {
  text-align: center;
  align-items: center;
  margin-top: 20px;
  display: flex;
}

.smallText_2677c1 {
  text-align: center;
  align-items: center;
  display: flex;
}

.headerContainer_2677c1 {
  background: #d6ead2;
  align-items: center;
  padding: 10px 0 10px 40px;
  display: flex;
}

@media (width >= 767px) {
  .smallText_2677c1 {
    font-size: 20px;
  }
}

.headerContainer_0382ed {
  background: #d6ead2;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px 40px;
  display: flex;
}

@media (width <= 767px) {
  .headerContainer_0382ed {
    padding: 15px 20px;
  }
}

.navigation_37bf77 {
  z-index: 1100;
  margin-top: -40px;
  position: sticky;
  top: 0;
}

.container_37bf77 {
  height: 100%;
  padding: 20px 40px;
}

@media (width <= 767px) {
  .container_37bf77 {
    padding: 20px 10px;
  }
}

.header_468154 {
  border-bottom: 1px solid #e8e7e9;
  align-items: center;
  padding-bottom: 15px;
  display: flex;
}

.nodeLogo_468154 {
  height: 55px;
}

.titleAndMetadata_468154 {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.metadataTags_468154 {
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  display: flex;
}

.cardContainer_f05283 {
  cursor: pointer;
  background: #fff;
  border-radius: 15px;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 445px;
  height: 100%;
  display: flex;
  position: relative;
  overflow: visible;
  box-shadow: 0 5px 20px #6c757d33;
}

.innerCard_f05283 {
  border-radius: 15px 15px 0 0;
  flex-direction: column;
  display: flex;
}

.cardBody_f05283 {
  border-radius: 0 0 16px 16px;
  flex-direction: column;
  padding: 20px;
  display: flex;
}

.cardTitle_f05283 {
  text-align: center;
}

[data-card-expanded="true"] .innerCard_f05283 {
  flex-direction: column;
  flex: 1;
  max-height: calc(100% - 45px);
  display: flex;
  overflow: auto;
}

.openButton_ce8b2f {
  border-radius: 15px;
  height: 30px;
  padding: 0;
  font-size: .875rem;
  line-height: 1.25rem;
}

.icon_ce8b2f {
  padding: 0 8px;
}

.text_ce8b2f {
  padding-right: 8px;
}

.newDesignShareButton_ce8b2f {
  gap: 10px;
  height: 30px;
  padding: 10px 20px;
}

@media (width <= 767px) {
  .newDesignShareButton_ce8b2f {
    border-radius: 50%;
    width: 30px;
    padding: 10px;
  }
}

.cardContainer_3f66be {
  cursor: pointer;
  background: #fff;
  border-radius: 15px;
  flex-direction: column;
  width: 100%;
  max-width: 445px;
  display: flex;
  position: relative;
  overflow: visible;
  box-shadow: 0 5px 20px #6c757d33;
}

.innerCard_3f66be {
  border-radius: 15px 15px 0 0;
  flex-direction: column;
  display: flex;
}

.cardImageWrapper_3f66be {
  box-sizing: content-box;
  background: #dededf;
  border-radius: 15px 15px 0 0;
  width: 100%;
  height: 80px;
  padding: 20px 0;
  display: inline-block;
  overflow: hidden;
}

.cardBody_3f66be {
  border-radius: 0 0 16px 16px;
  padding: 20px;
}

.cardTitle_3f66be {
  color: #343a40;
  cursor: pointer;
  justify-content: space-evenly;
  font-size: 16px;
  font-weight: normal;
  line-height: 25px;
  display: flex;
}

.cardList_3f66be {
  margin: 0;
  padding: 0;
  list-style: none;
}

.cardListItem_3f66be {
  color: #6c757d;
  cursor: pointer;
  min-height: 24px;
  margin-top: 10px;
  font-size: 14px;
  line-height: 20px;
}

.cardListItemText_3f66be {
  color: #6c757d;
  word-break: break-word;
  margin-left: 1ch;
  font-size: 14px;
  line-height: 20px;
}

.cardListItemText_3f66be:hover, .bulletIcon_3f66be {
  color: #2b7ff2;
}

.cardBodyFooter_3f66be {
  cursor: pointer;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 20px 15px;
  font-size: 14px;
  display: flex;
}

.cardBodyFooter_3f66be span {
  color: #2b7ff2;
  border: none;
}

.cardBodyFooter_3f66be svg {
  color: #2b7ff2;
}

.pathLabel_3f66be {
  color: #adb5bd;
  flex: 1 0 auto;
  order: 1;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

[data-card-expanded="true"] .cardImageWrapper_3f66be {
  display: none;
}

[data-card-expanded="true"] .innerCard_3f66be {
  flex-direction: column;
  flex: 1;
  max-height: calc(100% - 45px);
  display: flex;
  overflow: auto;
}

[data-cardbackground] {
  background: #dededf !important;
}

[data-cardbackground*="biology"] {
  background: #d6ead2 !important;
}

[data-cardbackground*="chemistry"] {
  background: #f1d4b2 !important;
}

[data-cardbackground*="physics"] {
  background: #d3edfc !important;
}

[data-cardbackground*="mathematics"] {
  background: #d1d1d1 !important;
}

[data-cardbackground*="english"] {
  background: #b8e3e6 !important;
}

[data-cardbackground*="computing"] {
  background: #ddeeea !important;
}

[data-cardbackground*="science"] {
  background: #f0edf5 !important;
}

[data-cardbackground*="geometry"] {
  background: #38ada9 !important;
}

[data-cardbackground*="geometry"][data-curriculum="CCSS"] {
  background: #dededf !important;
}

[data-cardbackground*="design and technology"] {
  background: #e2e2ef !important;
}

[data-cardbackground*="numbers concept"], [data-cardbackground*="geometry concept"], [data-cardbackground*="algebra"], [data-cardbackground*="algebra concept"], [data-cardbackground*="measurement concept"], [data-cardbackground*="statistics and probability"], [data-cardbackground*="statistics concept"] {
  background: #dededf !important;
}

[data-cardbackground*="numeracy and mathematics"], [data-cardbackground*="numbers and quantities"], [data-cardbackground*="functions"] {
  background: #d1d1d1 !important;
}

[data-cardbackground*="earth and space science"] {
  background: #c9d1db !important;
}

[data-cardbackground*="earth and space science"] h1 {
  color: #fff !important;
}

[data-cardbackground*="life science"] {
  background: #d6ead2 !important;
}

[data-cardbackground*="computing"], [data-cardbackground*="network and the internet"], [data-cardbackground*="networks and the internet"], [data-cardbackground*="data and analysis"], [data-cardbackground*="impacts of computing"], [data-cardbackground*="algorithms and programming"], [data-cardbackground*="informatics"], [data-cardbackground*="computer science"] {
  background: #ddeeea !important;
}

[data-cardbackground*=" administration and support"], [data-cardbackground*="business management"], [data-cardbackground*="customer service and sales"], [data-cardbackground*="enterprise and entrepreneurship"], [data-cardbackground*="event management"], [data-cardbackground*="finance and accounting"], [data-cardbackground*="human resources"], [data-cardbackground*="marketing"], [data-cardbackground*="project management"] {
  background: #e5e5e5 !important;
}

[data-cardbackground*="anatomy and physiology"], [data-cardbackground*="coaching"], [data-cardbackground*="health, wellbeing and sport"], [data-cardbackground*="nutrition and sport"], [data-cardbackground*="outdoor and adventurous activities"], [data-cardbackground*="research methods"], [data-cardbackground*="sports psychology"], [data-cardbackground*="working in the sports industry"] [data-cardbackground*="health, wellbeing and exercise"], [data-cardbackground*="fitness testing and training"], [data-cardbackground*="nutrition for sports and performance"] {
  background: #eff4ff !important;
}

[data-cardbackground*="mens en natuur"] {
  background: #f8f6da !important;
}

[data-cardbackground*="mens en maatschappij"] {
  background: #f0eae8 !important;
}

[data-cardbackground*="burgerschap"] {
  background: #fff4f9 !important;
}

[data-cardbackground*="kunst en cultuur"] {
  background: #eae2e8 !important;
}

[data-cardbackground*="historische tijd - geschiedenis"] {
  background: #ffeaec !important;
}

[data-cardbackground*="wetenschap & techniek - m&n"] {
  background: #eae6f6 !important;
}

[data-cardbackground*="plantyn"], [data-cardbackground*="environmental sciences"] {
  background: #edebf4 !important;
}

[data-cardbackground*="life and earth sciences"] {
  background: #d6ead2 !important;
}

[data-cardbackground*="social studies"], [data-cardbackground*="social science"], [data-cardbackground*="history"] {
  background: #d4cfe3 !important;
}

[data-cardbackground*="geography"] {
  background: #eff2e6 !important;
}

[data-cardbackground*="civics"], [data-cardbackground*="political science"] {
  background: #e5ece6 !important;
}

[data-cardbackground*="learning skills"] {
  background: #e7fbfa !important;
}

[data-cardbackground*="life skills"] {
  background: #e9f6fa !important;
}

[data-cardbackground*="literacy skills"] {
  background: #dff3f1 !important;
}

[data-cardbackground*="kanjerkrant"], [data-cardbackground*="scoodle"] {
  background: #f0edf5 !important;
}

[data-cardbackground*="aardrijkskunde"] {
  background: #e5ece6 !important;
}

[data-cardbackground*="electro mechanical engineering"] {
  background: #edebf4 !important;
}

[data-cardbackground*="aarde en heelal"] {
  background: #c9d1db !important;
}

[data-cardbackground*="cultuur en godsdienst"] {
  background: #f0eae8 !important;
}

[data-cardbackground*="dieren"] {
  background: #d6ead2 !important;
}

[data-cardbackground*="eten en drinken"] {
  background: #f7edf3 !important;
}

[data-cardbackground*="geschiedenis"] {
  background: #ffeaec !important;
}

[data-cardbackground*="kunst en creatief"] {
  background: #eae2e8 !important;
}

[data-cardbackground*="lichaam en gevoel"] {
  background: #f0eae8 !important;
}

[data-cardbackground*="mens en samenleving"] {
  background: #f5e1ed !important;
}

[data-cardbackground*="natuur"] {
  background: #d6ead2 !important;
}

[data-cardbackground*="school"], [data-cardbackground*="sport en vrije tijd"] {
  background: #d3edfc !important;
}

[data-cardbackground*="wetenschap en techniek"] {
  background: #f1d4b2 !important;
}

[data-cardbackground*="wereld"] {
  background: #e5ece6 !important;
}

[data-cardbackground*="general science"] {
  background: #f0edf5 !important;
}

[data-cardbackground*="physical science"] {
  background: #d3edfc !important;
}

.container_aea9d9 {
  cursor: pointer;
  background: #fff;
  border-radius: 15px;
  flex-direction: column;
  height: 100%;
  display: flex;
  overflow: hidden;
  box-shadow: 0 5px 20px #6c757d33;
}

.image_aea9d9 {
  box-sizing: content-box;
  background: #dededf;
  width: 100%;
  height: 80px;
  padding: 20px 0;
}

.name_aea9d9 {
  text-align: center;
  padding: 20px;
}

.container_eae4b3 {
  background-image: url("background.fdd954c6.png");
  background-size: cover;
  flex-direction: column;
  width: 100%;
  padding: 50px 40px 10px;
  display: flex;
}

.curriculumLogo_eae4b3 {
  fill: #fff;
  stroke-width: 1px;
  stroke: var(--border-colors-color-border-primary, #cbcbcc);
  background-color: #fff;
  border-radius: 100%;
  justify-items: center;
  width: 60px;
  height: 60px;
  padding: 10px;
  display: flex;
}

.curriculumMissingLogo_eae4b3 {
  background-color: #69bfac;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.curriculumDetails_eae4b3 {
  text-shadow: 0 1px 2px #35353573;
  align-items: center;
  gap: 20px;
  display: flex;
}

.curriculumDetails_eae4b3 img {
  width: 62px;
}

.subheader_eae4b3 {
  background-color: #fff;
  border-bottom: 1px solid #cbcbcc;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  height: 50px;
  padding: 10px 40px;
  display: flex;
}

.shareButtonContainer_eae4b3 {
  align-items: center;
  gap: 20px;
  display: flex;
}

.createCollectionButton_eae4b3 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 30px;
  padding: 10px 20px;
  display: flex;
}

@media (width <= 767px) {
  .createCollectionButton_eae4b3 {
    border-radius: 50%;
    width: 30px;
    padding: 10px;
  }
}

.shareButton_eae4b3 {
  gap: 10px;
  height: 30px;
  padding: 10px 20px;
}

@media (width <= 991px) {
  .subheader_eae4b3 {
    justify-content: space-between;
  }
}

@media (width <= 767px) {
  .container_eae4b3 {
    padding: 50px 10px 10px;
  }

  .subheader_eae4b3 {
    padding: 10px;
  }
}

.logoContainer_2d8f8e {
  background: #fff;
  border: 1px solid #cbcbcc;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  min-width: 62px;
  max-width: 62px;
  height: 62px;
  padding: 10px;
  display: flex;
}

.curriculumLogo_2d8f8e {
  max-width: 40px;
  max-height: 40px;
}

.curriculumMissingLogo_2d8f8e {
  background: #69bfac;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  display: flex;
}

.container_70996c {
  flex-direction: column;
  gap: 20px;
  max-width: 700px;
  padding: 20px 0;
  display: flex;
}

.selectAll_70996c {
  align-items: center;
  gap: 10px;
  display: flex;
}

@media (width <= 991px) {
  .container_70996c {
    max-width: unset;
  }
}

.subjectCard_4c81c3 {
  cursor: pointer;
  background: #dededf;
  border-radius: 15px;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 186px;
  padding: 15px;
  display: flex;
  box-shadow: 0 5px 20px #6c757d33;
}

.imageClass_4c81c3 {
  max-height: 90px;
}

.subjectTitle_4c81c3 {
  -webkit-line-clamp: 2;
  word-break: break-word;
  text-align: center;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

@media (width <= 413px) {
  .subjectCard_4c81c3 {
    height: 155px;
  }

  .subjectTitle_4c81c3 {
    -webkit-line-clamp: 1;
  }
}

.container_bf3741 {
  background: #f8f9fa;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  margin-left: auto;
  display: flex;
  overflow: hidden auto;
}

.querySelector_e1bed5 {
  place-self: center flex-end;
  align-items: center;
  display: flex;
}

.selectWrapper_e1bed5 {
  margin-left: 20px;
  margin-right: 20px;
}

.selectWrapper_e1bed5 select {
  color: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #2b7ff2;
  border: none;
  border-radius: 45px;
  padding: .5em 3.5em .5em 1em;
  font-size: 16px;
  line-height: 25px;
  display: inline-block;
  overflow: hidden;
}

.selectWrapper_e1bed5 select.selectStyle_e1bed5 {
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.50195 6.2168C4.76562 6.48047 5.20508 6.48047 5.46875 6.2168L9.45312 2.23242C9.74609 1.93945 9.74609 1.5 9.45312 1.23633L8.80859 0.5625C8.51562 0.298828 8.07617 0.298828 7.8125 0.5625L4.9707 3.4043L2.1582 0.5625C1.89453 0.298828 1.45508 0.298828 1.16211 0.5625L0.517578 1.23633C0.224609 1.5 0.224609 1.93945 0.517578 2.23242L4.50195 6.2168Z' fill='white'/%3E%3C/svg%3E%0A");
  background-position: right 1.3em top 50%, 0 0;
  background-repeat: no-repeat, repeat;
  background-size: .65em, 100%;
  outline: 0;
  font-size: 16px;
}

.topicTitleStyle_e1bed5 {
  font-size: 14px;
  line-height: 20px;
  display: block;
}

.queryTitleStyle_e1bed5 {
  justify-content: flex-start;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  display: flex;
}

@media (width <= 767px) {
  .topicTitleStyle_e1bed5 {
    font-size: 12px;
  }

  .queryTitleStyle_e1bed5 {
    justify-content: space-between;
    font-size: 16px;
    line-height: 20px;
  }
}

.buttonStyle_2aae98 {
  color: #fff;
  cursor: pointer;
  background-color: #2b7ff2;
  border: none;
  border-radius: 45px;
  justify-content: space-between;
  place-self: center end;
  place-items: center;
  width: 100%;
  height: 45px;
  padding: 10px 20px;
  display: flex;
}

.labelStyle_2aae98 {
  cursor: pointer;
  margin-right: 20px;
  font-size: 16px;
  line-height: 25px;
}

.button_e3f814 {
  color: #6c757d;
  cursor: pointer;
  background: #fff;
  border: 1px solid #e8e7e9;
  border-radius: 45px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 45px;
  padding: 11px 20px 9px;
}

.container_e3f814 {
  z-index: 1000;
  background: none;
  position: fixed;
  inset: 0;
}

.list_e3f814 {
  cursor: pointer;
  z-index: 1001;
  background: #fff;
  border: 1px solid #e8e7e9;
  border-radius: 22px;
  flex-direction: column;
  width: 100%;
  display: flex;
  position: absolute;
  box-shadow: 0 10px 10px #6c757d;
}

.title_e3f814 {
  color: #6c757d;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  padding: 10px 20px;
  display: flex;
}

.item_e3f814 {
  color: #343a40;
  border-top: 1px solid #cbcbcc;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  line-height: 25px;
  display: flex;
}

.container_92d062 {
  flex-direction: column;
  padding: 20px 0 10px 20px;
  display: flex;
}

.title_92d062 {
  color: #343a40;
  padding-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
  line-height: 25px;
}

.text_92d062 {
  color: #343a40;
  padding-bottom: 10px;
  line-height: 25px;
}

.supportTeamText_92d062 {
  color: #2b7ff2;
  cursor: pointer;
}

.card_1672cd {
  background-color: #fff;
  border-radius: 15px;
  max-width: 100%;
  min-height: 480px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 5px 20px #6c757d33;
}

.container_1672cd {
  padding: 20px 20px 10px;
  position: relative;
}

.visualContainer_1672cd {
  justify-content: center;
  height: 150px;
  display: flex;
  position: relative;
}

.selectCheckbox_1672cd {
  letter-spacing: 0;
  text-align: left;
  background-color: #fff;
  border: 1px solid #cbcbcc;
  border-radius: 5px;
  align-items: center;
  gap: 5px;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  display: flex;
  position: absolute;
  top: 10px;
  left: 10px;
}

.checkbox_1672cd {
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin: 0;
}

.imageContainer_1672cd {
  cursor: pointer;
  border-radius: 5px;
  justify-content: center;
  place-items: center;
  width: 100%;
  min-height: 150px;
  max-height: 150px;
  display: flex;
  position: relative;
  overflow: hidden;
}

div[data-numberofresults="5"] .container_1672cd:first-child[data-large-screen="true"][data-mobile-screen="false"] .imageContainer_1672cd {
  grid-row: 1 / -1;
  grid-column-start: 2;
  justify-self: end;
  min-width: 275px;
  max-width: 420px;
  max-height: 500px;
}

.videoIconContainer_1672cd {
  position: absolute;
  top: 80px;
  left: 16px;
}

.videoIcon_1672cd {
  color: #e30613;
  background-color: #fff;
  border: 2px solid #03392a;
  border-radius: 50%;
  width: 100%;
  font-size: 3.8rem;
  position: absolute;
  left: 16px;
}

.videoIconContainer_1672cd svg.videoIcon_1672cd {
  width: 60px;
  height: 60px;
}

.textContainer_1672cd {
  flex-direction: column;
  justify-content: space-between;
  height: 300px;
  display: flex;
  overflow: hidden;
}

.feedbackFooter_1672cd {
  background: #f3f3f6;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
  padding: 10px 20px;
  display: flex;
}

.badgeIconContainer_6c1dd8 {
  position: absolute;
  top: 0;
  right: 0;
}

.badgeIconContainer_6c1dd8 img {
  width: 128px;
  margin: 0;
  padding: 0;
}

.premiumIconFooterContainer_6c1dd8 {
  place-items: center;
  height: 28px;
  margin-right: 8px;
  display: flex;
}

.premiumIconFooterContainer_6c1dd8 img {
  height: 100%;
  margin: 0;
  padding: 0;
}

.resultsFooter_4142f7 {
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
  display: flex;
  overflow: hidden;
}

.resultLogo_4142f7 {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  align-items: center;
  min-width: 28px;
  max-width: 100%;
  margin-right: 16px;
  padding: 2px;
  display: flex;
  overflow: hidden;
}

.resultLink_4142f7 {
  color: #2b7ff2;
  cursor: pointer;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
  display: flex;
}

.sourceLogoImg_4142f7 {
  width: auto;
  height: 24px;
  margin: 0;
  padding: 0;
}

.footer_13e955 {
  align-self: stretch;
  align-items: center;
  padding: 10px 15px;
  display: flex;
}

.footerButton_13e955 {
  gap: 10px;
  height: 30px;
  padding: 10px 20px;
}

.footerText_13e955 {
  color: #2b7ff2;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
  display: flex;
}

.noCollections_f4901a {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  display: flex;
}

.addCollectionButton_f4901a {
  gap: 10px;
  height: 30px;
  padding: 10px 20px;
  font-size: .75rem;
}

.container_fb64e6 {
  cursor: pointer;
  background: #fff;
  border: 1px solid #cbcbcc;
  border-radius: 15px;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  height: 300px;
  min-height: 100%;
  display: flex;
  overflow: hidden;
}

.header_fb64e6 {
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  padding: 10px 15px;
  display: flex;
}

.iconCircle_fb64e6 {
  border-radius: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 40px;
  height: 40px;
  padding: 2px 8px;
  display: flex;
}

.icon_fb64e6 {
  width: 28px;
  height: 28px;
}

.body_fb64e6 {
  padding: 0 15px;
}

.footer_fb64e6 {
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  display: flex;
}

.container_b034bb {
  justify-content: center;
  align-items: center;
  gap: 20px;
  display: flex;
}

.footer_b034bb {
  flex-direction: column;
  align-items: center;
  gap: 5px;
  padding: 0 15px 30px;
  display: flex;
}

.buttonStyle_ab0660 {
  justify-content: center;
  align-items: center;
  height: 43px;
  padding: 16px 30px;
  display: flex;
}

.container_426e36, .container_35f9a2 {
  justify-content: center;
  align-items: center;
  gap: 20px;
  display: flex;
}

.footer_35f9a2 {
  flex-direction: column;
  align-items: center;
  gap: 5px;
  padding: 0 15px 30px;
  display: flex;
}

.container_ce81d6 {
  backdrop-filter: blur(5px);
  background: #f3f3f6b3;
  border: 1px solid #e8e7e9;
  border-radius: 15px;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 20px;
  display: flex;
  box-shadow: 0 4px 4px #00000040;
}

.curiculumAndGrade_ce81d6 {
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  display: flex;
  overflow: hidden;
}

.curriculum_ce81d6 {
  -webkit-line-clamp: 1;
  word-break: break-all;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.footer_ce81d6 {
  backdrop-filter: blur(5px);
  background: #f3f3f64d;
  border-top: 1px solid #e8e7e9;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  padding: 10px 15px;
  display: flex;
}

.footerEdit_ce81d6 {
  background: #dfecfd;
  border-radius: 5px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  width: 30px;
  height: 30px;
  display: flex;
}

.footerText_ce81d6 {
  color: #2b7ff2;
  gap: 10px;
  display: flex;
}

.container_b0108b {
  justify-content: center;
  align-items: center;
  gap: 20px;
  display: flex;
}

.title_615ee2 {
  border-bottom: 1px solid #cbcbcc;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 2px;
  display: flex;
}

.emptyCollectionMessage_615ee2 {
  padding: 20px 40px;
}

@media (width <= 767px) {
  .emptyCollectionMessage_615ee2 {
    padding: 20px;
  }
}

.forgotPasswordContainer_754126 {
  width: 100%;
}

.title_754126 {
  margin-bottom: 40px;
}

@media (width <= 991px) {
  .title_754126 {
    margin-bottom: 30px;
  }
}

.formError_754126 {
  color: #2a244b;
  background: #dcdae9 linear-gradient(#e1dfec, #dcdae9) repeat-x;
  border-color: #cecbe0;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 16px;
  line-height: 24px;
}

.formContainer_754126 {
  flex-direction: column;
  gap: 40px;
  display: flex;
}

@media (width <= 991px) {
  .formContainer_754126 {
    gap: 30px;
  }
}

.messageAndButtonContainer_754126 {
  flex-direction: column;
  gap: 40px;
  display: flex;
}

@media (width <= 991px) {
  .messageAndButtonContainer_754126 {
    gap: 30px;
  }
}

.buttonStyle_754126 {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 45px;
  padding: 16px 30px;
  display: flex;
}

.mainContainer_e56d61 {
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  overflow: hidden;
}

.headerContainer_e56d61 {
  background-color: #f3f3f6;
  border-bottom: 1px solid #cbcbcc;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 71px;
  padding: 15px 40px;
  display: flex;
}

@media (width <= 991px) {
  .headerContainer_e56d61 {
    padding: 15px 20px;
  }
}

@media (width <= 767px) {
  .headerContainer_e56d61 {
    padding: 15px 10px;
  }
}

.logoContainer_e56d61 {
  height: 40px;
}

.buttonsContainer_e56d61 {
  align-items: center;
  gap: 20px;
  display: flex;
}

.contentContainer_e56d61 {
  justify-content: center;
  width: 100%;
  height: 100%;
  display: flex;
  overflow-y: auto;
}

.childrenContainer_e56d61 {
  background: #fff;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 520px;
  min-width: 520px;
  height: fit-content;
  min-height: 100%;
  padding: 40px;
  display: flex;
}

@media (width <= 991px) {
  .childrenContainer_e56d61 {
    width: 466px;
    min-width: 466px;
    padding: 20px;
  }
}

@media (width <= 767px) {
  .childrenContainer_e56d61 {
    width: 100%;
    min-width: 100%;
    padding: 20px 10px;
  }
}

.backgroundContainer_e56d61 {
  background-image: url("laptop-classroom.8aff2e29.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: flex-end;
  width: 100%;
  padding: 0;
  display: flex;
  position: sticky;
  top: 0;
}

.backgroundContainerGradient_e56d61 {
  background: linear-gradient(#0000 0%, #0000006b 100%);
  position: relative;
  inset: 0;
}

.footer_e56d61 {
  flex-direction: column;
  width: 100%;
  padding: 40px;
  display: flex;
}

@media screen and (width <= 1440px) {
  .footer_e56d61 {
    padding-bottom: 60px;
  }
}

.languageContainer_d6bf8c {
  background: #2b7ff2;
  border: 1px solid #2371dd;
  border-radius: 30px;
  align-items: center;
  gap: 4px;
  height: 30px;
  padding: 4px;
  display: flex;
}

.languageButton_d6bf8c {
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  display: flex;
}

.languageButton_d6bf8c[data-selected="true"] {
  background: #fff;
}

.languageButton_d6bf8c > span {
  color: #fff;
}

.languageButton_d6bf8c[data-selected="true"] > span {
  color: #2b7ff2;
}

.loginContainer_abc636 {
  width: 100%;
}

.title_abc636 {
  margin-bottom: 20px;
}

@media (width <= 991px) {
  .title_abc636 {
    margin-bottom: 15px;
  }
}

.formError_abc636 {
  color: #2a244b;
  background: #dcdae9 linear-gradient(#e1dfec, #dcdae9) repeat-x;
  border-color: #cecbe0;
  border-radius: 8px;
  padding: 16px;
  line-height: 24px;
}

.formContainer_abc636 {
  flex-direction: column;
  gap: 20px;
  padding: 20px 0;
  display: flex;
}

@media (width <= 991px) {
  .formContainer_abc636 {
    padding: 15px 0;
  }
}

.formSubmitButton_abc636 {
  justify-content: center;
  align-items: center;
  height: 45px;
  margin-top: 20px;
  padding: 16px 30px;
  display: flex;
}

@media (width <= 991px) {
  .formSubmitButton_abc636 {
    margin-top: 15px;
  }
}

.forgotPassword_abc636 {
  text-align: right;
  color: #2b7ff2;
  text-decoration: underline;
}

.createAccountButton_abc636 {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 45px;
  padding: 16px 30px;
  display: flex;
}

.passwordResetContainer_f84b38 {
  flex-direction: column;
  gap: 40px;
  width: 100%;
  display: flex;
}

@media (width <= 991px) {
  .passwordResetContainer_f84b38 {
    gap: 30px;
  }
}

.formError_f84b38 {
  color: #2a244b;
  background: #dcdae9 linear-gradient(#e1dfec, #dcdae9) repeat-x;
  border-color: #cecbe0;
  border-radius: 8px;
  padding: 16px;
  line-height: 24px;
}

.formContainer_f84b38 {
  flex-direction: column;
  align-items: center;
  gap: 20px;
  display: flex;
}

.formSubmitButton_f84b38 {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 45px;
  margin-top: 20px;
  padding: 16px 30px;
  display: flex;
}

@media (width <= 991px) {
  .formSubmitButton_f84b38 {
    margin-top: 10px;
  }
}

.buttonStyle_f84b38 {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 45px;
  padding: 16px 30px;
  display: flex;
}

.signupContainer_4ebea0 {
  width: 100%;
}

.title_4ebea0 {
  margin-bottom: 20px;
}

@media (width <= 991px) {
  .title_4ebea0 {
    margin-bottom: 15px;
  }
}

.formError_4ebea0 {
  color: #2a244b;
  background: #dcdae9 linear-gradient(#e1dfec, #dcdae9) repeat-x;
  border-color: #cecbe0;
  border-radius: 8px;
  padding: 16px;
  line-height: 24px;
}

.formContainer_4ebea0 {
  flex-direction: column;
  gap: 20px;
  padding: 20px 0;
  display: flex;
}

@media (width <= 991px) {
  .formContainer_4ebea0 {
    padding: 15px 0;
  }
}

.formSubmitButton_4ebea0 {
  justify-content: center;
  align-items: center;
  height: 45px;
  margin-top: 20px;
  padding: 16px 30px;
  display: flex;
}

@media (width <= 991px) {
  .formSubmitButton_4ebea0 {
    margin-top: 15px;
  }
}

.disclaimers_4ebea0 {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

a {
  text-align: right;
  color: #2b7ff2;
  text-decoration: underline;
}

.newsletter_4ebea0 {
  align-items: center;
  gap: 12px;
  display: flex;
}

.checkbox_4ebea0 {
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin: 0;
}

.loginButton_4ebea0 {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 45px;
  padding: 16px 30px;
  display: flex;
}

.verifyEmailContainer_e08be1 {
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 100%;
  display: flex;
}

@media (width <= 991px) {
  .verifyEmailContainer_e08be1 {
    gap: 30px;
  }
}

.imageStyle_e08be1 {
  width: 205px;
}

@media (width <= 767px) {
  .imageStyle_e08be1 {
    width: 102px;
  }
}

.notVerifiedButtons_e08be1 {
  flex-direction: column;
  gap: 12px;
  width: 100%;
  display: flex;
}

.buttonStyle_e08be1 {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 45px;
  padding: 16px 30px;
  display: flex;
}

.sendConfirmationAgainFormContainer_e08be1 {
  width: 100%;
}

.formError_e08be1 {
  color: #2a244b;
  background: #dcdae9 linear-gradient(#e1dfec, #dcdae9) repeat-x;
  border-color: #cecbe0;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 16px;
  line-height: 24px;
}

.formContainer_e08be1 {
  flex-direction: column;
  gap: 40px;
  display: flex;
}

@media (width <= 991px) {
  .formContainer_e08be1 {
    gap: 30px;
  }
}

.questionTypes_f723c3 {
  flex-direction: column;
  align-self: stretch;
  gap: 20px;
  margin-top: 10px;
  display: flex;
}

.numberOfQuestions_f723c3 {
  flex-direction: column;
  align-self: stretch;
  gap: 10px;
  width: 100%;
  margin-top: 10px;
  display: flex;
}

.numberButtons_f723c3 {
  background: #f0f6ff;
  border-radius: 5px;
  display: flex;
}

.generateButtonContainer_f723c3 {
  background: #f8f9fa;
  border: 1px solid #cbcbcc;
  border-radius: 0 0 15px 15px;
  width: calc(100% + 42px);
  margin: auto -21px -21px;
  padding: 20px;
}

.generateButton_f723c3 {
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 16px 30px;
  display: flex;
}

.numberButton_4d024a {
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 10%;
  height: 43px;
  display: flex;
}

.container_b3c59d {
  flex-direction: column;
  gap: 20px;
  height: 100%;
  display: flex;
}

.loading_b3c59d {
  background: #fff;
  border: 1px solid #770db8;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  display: flex;
  position: relative;
}

.container_348018 {
  background: #fff;
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 5px;
  padding: 15px;
  display: flex;
  box-shadow: 0 4px 8px #00000014, 0 1px 2px #6c757d3d;
}

.title_348018 {
  border-bottom: 1px solid #cbcbcc;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  padding-bottom: 9px;
  display: flex;
  position: relative;
}

.buttonsContainer_348018 {
  align-items: center;
  gap: 5px;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.saveButton_348018 {
  border-radius: 5px;
  height: 25px;
  padding: 5px 10px;
}

.cancelButton_348018 {
  border: 1px solid #2b7ff2;
  border-radius: 5px;
  height: 25px;
  padding: 5px 10px;
}

.questionContainer_348018 {
  background: #f3f3f6;
  border-radius: 15px;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 5px;
  margin-top: 10px;
  padding: 20px;
  display: flex;
}

.typeButtonsContainer_348018 {
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  display: flex;
}

.typeButton_348018 {
  background: #f0f6ff;
  height: 30px;
  padding: 10px 20px;
}

.container_83774f {
  background: #fff;
  border-radius: 15px;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 5px;
  padding: 10px;
  display: flex;
  box-shadow: 0 1px 2px #6c757d3d, 0 4px 8px #00000014;
}

.header_83774f {
  align-self: stretch;
  align-items: flex-start;
  gap: 5px;
  min-height: 25px;
  display: flex;
}

.headerDragAndQuestion_83774f {
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  display: flex;
}

.dragHandle_83774f {
  cursor: move;
  justify-content: center;
  align-items: center;
  gap: 1px;
  padding-top: 5px;
  display: flex;
}

.buttonsContainer_83774f {
  align-items: center;
  gap: 10px;
  display: flex;
}

.answers_83774f {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  padding: 10px 0 10px 30px;
  display: flex;
}

.answer_83774f {
  align-items: center;
  gap: 10px;
  display: flex;
}

.editAnswer_83774f {
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.circle_83774f {
  border: 1px solid #cbcbcc;
  border-radius: 50%;
  width: 17px;
  min-width: 17px;
  height: 17px;
}

.save_83774f {
  border-radius: 5px;
  height: 25px;
  padding: 5px 10px;
}

.cancel_83774f {
  border: 1px solid #2b7ff2;
  border-radius: 5px;
  height: 25px;
  padding: 5px 10px;
}

.markAsCorrect_83774f {
  background: #f0f6ff;
  border: 1px solid #2b7ff2;
  border-radius: 5px;
  height: 20px;
  padding: 5px 8px;
}

.container_6d4035 {
  flex-direction: column;
  gap: 40px;
  width: 100%;
  height: 100%;
  display: flex;
}

.mainTitle_6d4035 {
  background: #fff;
  border: 1px solid #770db8;
  border-radius: 5px;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 10px;
  display: flex;
}

.tabs_9037ce {
  box-shadow: none;
  background: #f8f9fa;
  border-bottom: 1px solid #cbcbcc;
}

.profileContainer_5e3e85 {
  flex-direction: column;
  gap: 1.25rem;
  padding-top: 10px;
  display: flex;
}

.mainHeading_5e3e85 {
  color: #343a40;
  text-transform: none;
  border-bottom: 1px solid #e8e7e9;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.profileFormGroup_5e3e85 {
  flex-direction: column;
  gap: 1.25rem;
  max-width: 325px;
  display: flex;
}

.changeButton_5e3e85 {
  color: #2b7ff2;
  cursor: pointer;
  margin-top: -10px;
  margin-bottom: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: block;
}

.formContainer_5e3e85 {
  max-width: 500px;
  margin-top: .625rem;
}

.supportText_5e3e85 {
  color: #343a40;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 2.5rem !important;
}

.errorAlert_5e3e85 {
  color: #e30613;
  margin-top: -10px;
  margin-bottom: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.successAlert_5e3e85 {
  color: #5fc3ad;
  margin-top: -10px;
  margin-bottom: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.supportText_5e3e85 span {
  color: #e30613;
  font-weight: 700;
}

.supportText_5e3e85 a {
  color: #2b7ff2;
  font-weight: 700;
  text-decoration: none;
}

.passwordFormContainer_d44a3c {
  background: #f8f9fa;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
}

.title_d44a3c {
  background: #f3f3f6;
  border-bottom: 1px solid #cbcbcc;
}

.closeButton_d44a3c {
  color: #6c757d;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  position: absolute;
  right: 1.25rem;
}

.passwordError_d44a3c {
  color: #e30613;
  gap: 0;
  margin-bottom: .625rem;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
}

.inputLabel_659913 {
  color: #6c757d;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.dropdownIcon_659913 {
  color: #6c757d;
  text-align: center;
  cursor: pointer;
  pointer-events: "none";
  font-size: 15px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px;
  position: absolute;
  right: 15px;
}

.section_3a466d {
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  max-width: 420px;
  margin-bottom: 10px;
  display: flex;
}

.container_3a466d {
  background: #f8f9fa;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  max-width: 670px;
  padding: 20px 0;
  display: flex;
}

.title_3a466d {
  border-bottom: 1px solid #e8e7e9;
  padding-bottom: 5px;
}

.select_3a466d {
  flex-direction: row;
  gap: 20px;
  max-width: 420px;
  line-height: 45px;
  display: flex;
}

.selectInput_3a466d {
  background-color: #fff;
  border: 1px solid #adb5bd;
  border-radius: 5px;
  flex-grow: 1;
  max-height: 45px;
  padding-right: 15px;
}

.icon_3a466d {
  color: #6c757d;
  font-size: 15px;
}

.footer_3a466d {
  max-width: 325px;
  margin-top: 10px;
}

.numberOfResultsStyle_68ad44 {
  text-align: center;
  border: 1px solid #adb5bd;
  border-radius: 5px;
  outline: none;
  width: 41px;
  height: 24px;
  font-size: 14px;
  font-weight: 300;
  position: relative;
}

.numberOfResultsStyle_68ad44::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

.headerContainer_606e6c {
  background: #f3f3f6;
  border-bottom: 1px solid #cbcbcc;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 45px;
  padding: 10px 40px;
  display: flex;
}

@media (width <= 767px) {
  .headerContainer_606e6c {
    height: unset;
    padding: 10px;
  }
}
/*# sourceMappingURL=index.fe4d0ae0.css.map */
